import Image from 'next/image';
import React, { FC, MouseEventHandler } from 'react';
import CSS from 'csstype';
import { Oval } from 'react-loader-spinner';

import styles from './BasicButton.module.css';
import { NextImageBasicData } from '../../../types/Next';

export interface BasicButtonProps {
  id?: string;
  theme?:
    | 'light'
    | 'dark'
    | 'white'
    | 'error'
    | 'blue'
    | 'blue-white'
    | 'red'
    | 'gray'
    | 'red-orange'
    | 'shadow-gray'
    | 'gray-black'
    | 'orange'
    | 'orange-black';
  text?: string;
  image?: NextImageBasicData;
  rightImage?: NextImageBasicData;
  disabled: boolean;
  type: 'button' | 'submit';
  role?: string;
  onClick: MouseEventHandler<HTMLButtonElement> | (() => Promise<void>);
  extraStyles?: CSS.Properties;
  tooltip?: string;
  loading?: boolean;
  shadow?: boolean;
  boxShadow?: boolean;
  extraLarge?: boolean;
  extraSmall?: boolean;
  extraStyles_2?: React.CSSProperties;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  imageStyle?: React.CSSProperties;
}

export const BasicButton: FC<BasicButtonProps> = ({
  id,
  theme = 'light',
  text,
  image,
  rightImage,
  disabled,
  loading,
  role,
  type,
  onClick,
  extraStyles = {},
  tooltip,
  shadow,
  boxShadow,
  extraLarge,
  extraSmall,
  extraStyles_2,
  imageStyle,
  ...props
}) => {
  const buttonBackgroundColor = () => {
    switch (theme) {
      case 'dark':
        return 'var(--ea-black)';
      case 'light':
        return 'var(--ea-beige)';
      case 'white':
        return 'var(--ea-white)';
      case 'error':
        return 'var(--error-color)';
      case 'blue':
        return 'var(--ea-blue)';
      case 'red':
        return '#F95656';
      case 'gray':
        return '#CCCCCC';
      case 'red-orange':
        return '#FF7C62';
      case 'shadow-gray':
        return '#B2B2B2';
      case 'gray-black':
        return '#CCCCCC';
      case 'orange':
        return '#FF4E0C';
      case 'orange-black':
        return '#FF9141';
    }
  };

  const buttonColor = () => {
    switch (theme) {
      case 'error':
      case 'dark':
      case 'blue':
      case 'red':
      case 'gray':
        return 'var(--ea-beige)';
      case 'shadow-gray':
      case 'orange':
        return 'white';
      case 'light':
      case 'white':
      case 'red-orange':
      case 'gray-black':
      case 'orange-black':
        return 'var(--ea-black)';
    }
  };

  return (
    <button
      id={id}
      key={`${text || image?.src || ''}-${theme}`}
      type={type}
      role={role}
      disabled={disabled}
      onClick={(e) => {
        onClick(e);
      }}
      className={`${styles['button']} 
      ${shadow ? styles['shadow'] : ''} 
      ${extraLarge ? styles['extra-large'] : '' }
      ${extraSmall ? styles['extra-small'] : ''}
      ${boxShadow ? styles['box-shadow'] : ''}`}
      style={{
        color: buttonColor(),
        backgroundColor: buttonBackgroundColor(),
        border:
          theme === 'error' ||
          theme === 'blue' ||
          theme === 'gray' ||
          theme === 'shadow-gray'
            ? 'none'
            : '',
        ...extraStyles,
        ...extraStyles_2,
        
      }}
      {...props} 
    >
      {loading ? (
        <Oval
          ariaLabel="loading-indicator"
          height={16}
          width={16}
          strokeWidth={5}
          color="var(--ea-blue)"
          secondaryColor="var(--ea-light-blue)"
        />
      ) : (
        image && (
          <div className={styles['image-container']}>
            <Image
              src={image.src}
              alt=""
              layout="fixed"
              height={image.height}
              width={image.width}
              style={imageStyle}
            />
          </div>
        )
      )}
      {text && <b>{text}</b>}
      {rightImage && (
        <div className={styles['image-container']}>
          <Image
            src={rightImage.src}
            alt=""
            layout="fixed"
            objectFit="contain"
            height={rightImage.height}
            width={rightImage.width}
          />
        </div>
      )}
      {tooltip && (
        <div className={styles['tooltip']}>
          <p>{tooltip}</p>
        </div>
      )}
    </button>
  );
};
