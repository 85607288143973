import { FC } from 'react';

type MediaSvgProps = {
  stroke?: string;
};

export const MediaSvg: FC<MediaSvgProps> = ({
  stroke = 'black',
}) => {
  return (


<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.375 8.875L0.5 5V8.5C0.5 9.60457 1.39543 10.5 2.5 10.5H4.375V8.875Z" fill="#2D7734" stroke={stroke}/>
<path d="M0.5 3.5C0.5 2.39543 1.39543 1.5 2.5 1.5H9.5C10.6046 1.5 11.5 2.39543 11.5 3.5V8.5C11.5 9.60457 10.6046 10.5 9.5 10.5H2.5C1.39543 10.5 0.5 9.60457 0.5 8.5V3.5Z" stroke={stroke} strokeLinejoin="round"/>
<path d="M7.47209 5.3844L2.75 10.5L9.5 10.5C10.6046 10.5 11.5 9.60457 11.5 8.5V6.25L10.2082 5.19305C9.38914 4.52294 8.18987 4.60681 7.47209 5.3844Z" fill="#62FF72" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.25 5C3.14543 5 2.25 6 2.25 6.5L4.25 8.8175L6.25 6.5C6.25 6 5.35457 5 4.25 5Z" fill="#FFEF62" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
