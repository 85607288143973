import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';
import {
  ContentResponsesOutput,
  DislikeResponseInput,
  LandingPageData,
  LandingPageOutput,
  LikedResponsesOutput,
  LikeResponseInput,
} from '../types/API';
import { ContentResponse, LikedResponse } from '../types/FormTypes';
import { useAuthContext } from './AuthContext';
import { useFormContext } from './FormContext';
import { useOutputAreaContext } from './OuputAreaContext';
import { usePersonaContext } from './PersonaContext';


interface ResponseHistoryContextInterface {
  likedResponses: ContentResponse[] | undefined;
  // historyResponses: LikedResponse[] | undefined;
  contentResponses: ContentResponse[] | undefined;
  favoriteResponses: ContentResponse[] | undefined;
  chatResponses: ContentResponse[] | undefined;
  landingPageData: LandingPageData[] | undefined;
  // mutateLikedResponses: KeyedMutator<LikedResponse[]>;
  likedResponsesError: Error | undefined;
  contentResponsesError: Error | undefined;
  favoriteResponsesError: Error | undefined;
  chatResponsesError: Error | undefined;
  landingPageDataError: Error | undefined;
  loadMoreLikedResponses: () => Promise<void>;
  loadMoreContentResponses: () => Promise<void>;
  loadMoreFavoriteResponses: () => Promise<void>;
  loadMoreChatResponses: () => Promise<void>;
  loadMoreLandingPageData: () => Promise<void>;
  canLoadMoreLikedResponses: boolean;
  canLoadMoreContentResponses: boolean;
  canLoadMoreFavoriteResponses: boolean;
  canLoadMoreLandingPageData: boolean;
  setCanLoadMoreContentResponses: Dispatch<SetStateAction<boolean>>;
  canLoadMoreChatResponses: boolean;
  setCanLoadMoreChatResponses: Dispatch<SetStateAction<boolean>>;
  setCanLoadMoreFavoriteResponses: Dispatch<SetStateAction<boolean>>;
  setCanLoadMoreLikedResponses: Dispatch<SetStateAction<boolean>>;
  setCanLoadMoreLandingPageData: Dispatch<SetStateAction<boolean>>;
  mutateContentSWR: () => Promise<void>;
  mutateChatSWR: () => Promise<void>;
  mutateLikedSWR: () => Promise<void>; 
  mutateFavoriteSWR: () => Promise<void>;
  mutateLandingPageSWR: () => Promise<void>;
  likeResponse: (responseIndex?: number) => Promise<void>;
  dislikeResponse: (responseIndex?: number) => Promise<void>;
  favoriteResponse: (responseIndex?: number) => Promise<void>;
  favoriteSuperChatResponse: (
    response: string,
    brand: string,
    audience: string
  ) => Promise<void>;
  unfavoriteResponse: (id: string) => Promise<void>;
  loading: boolean;
}

const ResponseHistoryContext = createContext<
  ResponseHistoryContextInterface | undefined
>(undefined);

export const ResponseHistoryContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isSignedIn } = useAuthContext();
  const { generatedOutput, editedOutput } = useFormContext();
  const { setResponseRated, setSuccessMessage } = useOutputAreaContext();
  const { refreshAllContentTypes } = usePersonaContext();

  const {
    data: likedResponsesSWR,
    mutate: mutateLikedResponsesSWR,
    error: likedResponsesError,
  } = useSWR<ContentResponsesOutput, Error>(
    isSignedIn ? '/api/responses/likedResponses' : null
  );

  const {
    data: contentResponsesSWR,
    mutate: mutateContentResponsesSWR,
    error: contentResponsesError,
  } = useSWR<ContentResponsesOutput, Error>(
    isSignedIn ? '/api/responses/getContentHistory' : null
  );

  const {
    data: chatResponsesSWR,
    mutate: mutateChatResponsesSWR,
    error: chatResponsesError,
  } = useSWR<ContentResponsesOutput, Error>(
    isSignedIn ? '/api/responses/getChatHistory' : null
  );

  const {
    data: favoriteResponsesSWR,
    mutate: mutateFavoriteResponsesSWR,
    error: favoriteResponsesError,
  } = useSWR<ContentResponsesOutput, Error>(
    isSignedIn ? '/api/responses/getFavoriteHistory' : null
  );

  const {
    data: landingPageDataSWR,
    mutate: mutateLandingPageDataSWR,
    error: landingPageDataError,
  } = useSWR<LandingPageOutput, Error>(
    isSignedIn ? '/api/landingPage/getLandingPageData' : null
  );

  // const {
  //   data: recentHistorySWR,
  //   mutate: mutateRecentHistorySWR,
  //   error: recentHistoryError,
  // } = useSWR<LikedResponsesOutput, Error>(
  //   isSignedIn ? '/api/responses/getRecentHistory' : null
  // );

  const [likedResponses, setLikedResponses] = useState<ContentResponse[]>();
  const [historyResponses, setHistoryResponses] = useState<LikedResponse[]>();
  const [contentResponses, setContentResponses] = useState<ContentResponse[]>();
  const [favoriteResponses, setFavoriteResponses] =
    useState<ContentResponse[]>();
  const [chatResponses, setChatResponses] = useState<ContentResponse[]>();
  const [landingPageData, setLandingPageData] = useState<LandingPageData[]>();
  const [canLoadMoreLikedResponses, setCanLoadMoreLikedResponses] =
    useState<boolean>(false);
  const [canLoadMoreContentResponses, setCanLoadMoreContentResponses] =
    useState<boolean>(false);
  const [canLoadMoreFavoriteResponses, setCanLoadMoreFavoriteResponses] =
    useState<boolean>(false);
  const [canLoadMoreChatResponses, setCanLoadMoreChatResponses] =
    useState<boolean>(false);
  const [canLoadMoreLandingPageData, setCanLoadMoreLandingPageData] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (likedResponsesSWR) {
      setLikedResponses(likedResponsesSWR.contentResponses);
      setCanLoadMoreLikedResponses(likedResponsesSWR.canLoadMore);
    }
  }, [likedResponsesSWR]);

  // useEffect(() => {
  //   if (recentHistorySWR) {
  //     setHistoryResponses(recentHistorySWR.likedResponses);
  //   }
  // }, [recentHistorySWR]);

  useEffect(() => {
    if (contentResponsesSWR) {
      setContentResponses(contentResponsesSWR.contentResponses);
      setCanLoadMoreContentResponses(contentResponsesSWR.canLoadMore);
    }
  }, [contentResponsesSWR]);

  useEffect(() => {
    if (favoriteResponsesSWR) {
      setFavoriteResponses(favoriteResponsesSWR.contentResponses);
      setCanLoadMoreFavoriteResponses(favoriteResponsesSWR.canLoadMore);
    }
  }, [favoriteResponsesSWR]);

  useEffect(() => {
    if (chatResponsesSWR) {
      setChatResponses(chatResponsesSWR.contentResponses);
      setCanLoadMoreChatResponses(chatResponsesSWR.canLoadMore);
    }
  }, [chatResponsesSWR]);

  useEffect(() => {
    if (landingPageDataSWR) {
      setLandingPageData(landingPageDataSWR.landingPageData);
      setCanLoadMoreLandingPageData(landingPageDataSWR.canLoadMore);
    }
  }, [landingPageDataSWR]);

  useEffect(() => {
    const fetchData = async () => {
      await mutateChatResponsesSWR();
      await mutateContentResponsesSWR();
      await mutateLikedSWR();
      // await mutateRecentHistorySWR();
      await mutateFavoriteResponsesSWR();
      await mutateLandingPageDataSWR();
      console.log('Content has been updated!');
    };
    fetchData();
  }, [refreshAllContentTypes]);

  const loadMoreLikedResponses = async () => {
    if (!likedResponses) return;
    setLoading(true);
    try {
      const { data: nextLikedResponses } =
        await axios.get<ContentResponsesOutput>(
          `/api/responses/likedResponses?startAfterId=${
            likedResponses[likedResponses.length - 1].id
          }`
        );
      const newLikedResponses = [
        ...likedResponses,
        ...nextLikedResponses.contentResponses,
      ];
      setLikedResponses(newLikedResponses);
      mutateLikedResponsesSWR();
      mixpanel.track('Load more saved responses', {
        totalNumResponsesLoaded: newLikedResponses.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreContentResponses = async () => {
    if (!contentResponses) return;
    setLoading(true);
    try {
      const { data: nextContentResponses } =
        await axios.get<ContentResponsesOutput>(
          `/api/responses/getContentHistory?startAfterId=${
            contentResponses[contentResponses.length - 1].id
          }`
        );
      const newContentResponses = [
        ...contentResponses,
        ...nextContentResponses.contentResponses,
      ];
      setContentResponses(newContentResponses);
      mutateContentResponsesSWR();
      mixpanel.track('Load more content responses', {
        totalNumResponsesLoaded: newContentResponses.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreFavoriteResponses = async () => {
    if (!favoriteResponses) return;
    setLoading(true);
    try {
      const { data: nextContentResponses } =
        await axios.get<ContentResponsesOutput>(
          `/api/responses/getFavoriteHistory?startAfterId=${
            favoriteResponses[favoriteResponses.length - 1].id
          }`
        );
      const newContentResponses = [
        ...favoriteResponses,
        ...nextContentResponses.contentResponses,
      ];
      setFavoriteResponses(newContentResponses);
      setCanLoadMoreFavoriteResponses(nextContentResponses.canLoadMore);
      mutateFavoriteResponsesSWR();
      mixpanel.track('Load more favorite responses', {
        totalNumResponsesLoaded: newContentResponses.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreChatResponses = async () => {
    if (!chatResponses) return;
    setLoading(true);
    try {
      const { data: nextContentResponses } =
        await axios.get<ContentResponsesOutput>(
          `/api/responses/getChatHistory?startAfterId=${
            chatResponses[chatResponses.length - 1].id
          }`
        );
      const newChatResponses = [
        ...chatResponses,
        ...nextContentResponses.contentResponses,
      ];
      setChatResponses(newChatResponses);
      setCanLoadMoreChatResponses(nextContentResponses.canLoadMore);
      mutateChatResponsesSWR();
      mixpanel.track('Load more chat responses', {
        totalNumResponsesLoaded: newChatResponses.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreLandingPageData = async () => {
    if (!landingPageData) return;
    setLoading(true);
    try {
      const { data: nextLandingPageData } =
        await axios.get<LandingPageOutput>(
          `/api/landingPage/getLandingPageData?startAfterId=${
            landingPageData[landingPageData.length - 1].id
          }`
        );
      const newLandingPageData = [
        ...landingPageData,
        ...nextLandingPageData.landingPageData,
      ];
      setLandingPageData(newLandingPageData);
      setCanLoadMoreLandingPageData(nextLandingPageData.canLoadMore);
      mutateLandingPageDataSWR();
      mixpanel.track('Load more landing page data', {
        totalNumResponsesLoaded: newLandingPageData.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const likeResponse = async (responseIndex = 0) => {
    if (!generatedOutput[responseIndex] || !editedOutput[responseIndex]) return;
    try {
      const responseInput: LikeResponseInput = {
        originalResponse: generatedOutput[responseIndex]?.text,
        editedResponse: editedOutput[responseIndex]?.text,
      };
      const likedResponse = await axios.post<ContentResponse>(
        '/api/responses/like',
        responseInput
      );
      // const newLikedResponses: ContentResponse[] = likedResponses || [];
      // if (
      //   newLikedResponses.length >= 1 &&
      //   newLikedResponses[1].id === likedResponse.id
      // ) {
      //   // If the response is an edited version of a previously liked response, only keep the newest one
      //   newLikedResponses[0] = {
      //     id: likedResponse.id,
      //     response: likedResponse.response,
      //     type: likedResponse.type,

      //   };
      // } else {
      //   // If the response has never been liked before, simply add it
      //   newLikedResponses.unshift({
      //     id: likedResponse.id,
      //     response: likedResponse.response,
      //   });
      // }
      // setLikedResponses(newLikedResponses);
      mutateLikedResponsesSWR();
      // {
      //   contentResponses: newLikedResponses,
      //   canLoadMore: canLoadMoreLikedResponses,
      // },
      // {
      //   revalidate: false,
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setResponseRated(true);
      setSuccessMessage(
        'Thanks! Your feedback helps us improve your responses!'
      );
      mixpanel.track('Like response', {
        response: editedOutput,
      });
    }
  };

  const dislikeResponse = async (responseIndex = 0) => {
    if (!generatedOutput[responseIndex] || !editedOutput[responseIndex]) return;

    try {
      const responseInput: DislikeResponseInput = {
        originalResponse: generatedOutput[responseIndex].text,
        editedResponse: editedOutput[responseIndex].text,
      };
      await axios.post('/api/responses/dislike', responseInput);
    } catch (error) {
      console.log(error);
    } finally {
      setResponseRated(true);
      setSuccessMessage(
        'Thanks! Your feedback helps us improve your responses!'
      );
      mixpanel.track('Dislike response', {
        response: editedOutput,
      });
    }
  };

  const favoriteResponse = async (responseIndex = 0) => {
    if (!generatedOutput[responseIndex] || !editedOutput[responseIndex]) return;
    try {
      const docID = generatedOutput[responseIndex].id ?? '';
      const favoritedResponse = await axios.post(
        '/api/responses/favorite',
        docID
      );
      await mutateFavoriteResponsesSWR();
    } catch (error) {
      console.log(error);
    } finally {
      setResponseRated(true);
      setSuccessMessage('Favorited!');
      mixpanel.track('Favorite response', {
        response: editedOutput,
      });
    }
  };

  const favoriteSuperChatResponse = async (
    response: string,
    brand: string,
    audience: string
  ) => {
    try {
      const favoritedResponse = await axios.post(
        '/api/responses/favoriteSuperChat',
        { response, brand, audience }
      );
      await mutateFavoriteResponsesSWR();
    } catch (error) {
      console.log(error);
    } finally {
      setResponseRated(true);
      setSuccessMessage('Favorited!');
      mixpanel.track('Favorite Response', {
        response: editedOutput,
      });
    }
  };

  const unfavoriteResponse = async (id: string) => {
    try {
      const unfavoritedResponse = await axios.post(
        '/api/responses/unfavorite',
        id
      );
      await mutateFavoriteResponsesSWR();
    } catch (error) {
      console.log(error);
    } finally {
      setResponseRated(true);
      setSuccessMessage('unfavorited!');
      mixpanel.track('Favorite response', {
        response: editedOutput,
      });
    }
  };

  const mutateContentSWR = async () => {
    try {
      await mutateContentResponsesSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const mutateFavoriteSWR = async () => {
    try {
      await mutateFavoriteResponsesSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const mutateChatSWR = async () => {
    try {
      await mutateChatResponsesSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const mutateLikedSWR = async () => {
    try {
      await mutateLikedResponsesSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const mutateLandingPageSWR = async () => {
    try {
      await mutateLandingPageDataSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const refreshContentSWR = async () => {};

  return (
    <ResponseHistoryContext.Provider
      value={{
        likedResponses,
        likedResponsesError,
        contentResponsesError,
        favoriteResponsesError,
        chatResponsesError,
        landingPageDataError,
        loadMoreLikedResponses,
        loadMoreContentResponses,
        loadMoreChatResponses,
        loadMoreFavoriteResponses,
        loadMoreLandingPageData,
        canLoadMoreLikedResponses,
        canLoadMoreContentResponses,
        canLoadMoreChatResponses,
        canLoadMoreFavoriteResponses,
        canLoadMoreLandingPageData,
        setCanLoadMoreLikedResponses,
        setCanLoadMoreContentResponses,
        setCanLoadMoreChatResponses,
        setCanLoadMoreFavoriteResponses,
        setCanLoadMoreLandingPageData,
        contentResponses,
        chatResponses,
        favoriteResponses,
        landingPageData,
        likeResponse,
        dislikeResponse,
        favoriteResponse,
        favoriteSuperChatResponse,
        mutateContentSWR,
        mutateLikedSWR,
        mutateChatSWR,
        mutateFavoriteSWR,
        mutateLandingPageSWR,
        unfavoriteResponse,
        loading,
      }}
    >
      {children}
    </ResponseHistoryContext.Provider>
  );
};

export const useResponseHistoryContext =
  (): ResponseHistoryContextInterface => {
    const context = useContext(ResponseHistoryContext);
    if (context === undefined) {
      throw new Error(
        'ResponseHistoryContext must be within ResponseHistoryContextProvider'
      );
    }

    return context;
  };
